import React from "react";
import loadable from "@loadable/component";
import LpLayout from "../../components/Layout/lp-layout";
import SEO from "../../components/seo";
import ThankYou from "../../components/ConversionPath/demo-requested";
// import Image from '../../components/image';
// import TestmonialReviews from "../../components/TestimonialReviews/TestmonialReviews";
// import { restaurantTestimonials } from "../../data/restaurant-data";

const Articles = loadable(() => import("../../components/Articles/Articles"));

const restaurantArticles = {
  title: "Inspiration to help you compete and win",
  bgText: "Articles",
};

export default function ThankYouPage() {
  return (
    <LpLayout>
      <SEO title="Thank you - your request for a gated asset has been received | SpotOn " />

      <ThankYou
        phoneNumber=""
        phoneText=""
        thankYouMessage="Thank you for submitting your information."
        thankYouSubheader="Please check your email for the download you requested."
      />

      <Articles
        sectionData={restaurantArticles}
        blogFilters={{
          limit: 6,
          include: "tags,authors",
          filter: "tag:Restaurants",
        }}
        cornerStone={false}
        style={{ marginTop: 80, marginBottom: 60 }}
      />
    </LpLayout>
  );
}
